import _isEmpty from "lodash.isempty";

import { createCookie, getCookie } from "./cookieHelper";
import { querySearch } from "./api/hashQueryParameters";
import { getGtmId } from "./commonService";
import { getConfiguration } from "./configurationUtil";
import { getProduct } from "./tripUtil";

import store from "../../store";

function getUserPlatForm() {
  if (
    window.matchMedia("(display-mode: standalone)").matches ||
    window.navigator.standalone
  )
    return "PWA";
  if (
    navigator.userAgent.match(/Android/i) ||
    navigator.userAgent.match(/webOS/i) ||
    navigator.userAgent.match(/iPhone/i) ||
    navigator.userAgent.match(/iPad/i) ||
    navigator.userAgent.match(/iPod/i) ||
    navigator.userAgent.match(/BlackBerry/i) ||
    navigator.userAgent.match(/Windows Phone/i)
  )
    return "Mobile";
  return "Desktop";
}

const userPlatform = getUserPlatForm();
/**
 * Read the queryString and set utm source / gclid from the queryString.
 * If a source is present under following conditions, set it in the cookie
 * @return {null}
 */
export const initializeUtmSource = () => {
  const queryString = querySearch(window.location.hash);
  if (!_isEmpty(queryString)) {
    // stop utm tracking If utm source and gclid are undefined; or if notrack is defined
    if (
      (typeof queryString.utm_source === "undefined" &&
        typeof queryString.gclid === "undefined") ||
      typeof queryString.notrack !== "undefined"
    ) {
      return;
    }

    if (queryString.utm_source) {
      const utmMedium =
        typeof queryString.utm_medium !== "undefined"
          ? queryString.utm_medium.toLowerCase()
          : "";
      if (utmMedium == "redirect") {
        return;
      }

      let utmSource = queryString.utm_source.toLowerCase();
      // remove all text like "[xx]". facebook.com[123] becomes facebook.com
      utmSource =
        utmSource.indexOf("[") != -1
          ? utmSource.substr(0, utmSource.indexOf("["))
          : utmSource;
      createCookie("source", utmSource);
    } else if (typeof queryString.gclid !== "undefined") {
      if (queryString.gclid) {
        createCookie("source", "google.com");
      }
    }
  }
};

// Inserting tag manager js
const loadTagManagerScript = function () {
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js",
    });
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s);
    const dl = l != "dataLayer" ? `&l=${l}` : "";
    j.async = true;
    j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", getGtmId());
};

// inserting an iframe on page to view the tagmanager preview mode.
const insertTagManagerPreviewIframe = function () {
  const noscriptElement = document.createElement("noscript");
  const iframe = document.createElement("iframe");
  iframe.style.display = "none";
  iframe.style.visibility = "hidden";
  iframe.src = `https://www.googletagmanager.com/ns.html?id=${getGtmId()}`;
  noscriptElement.appendChild(iframe);
  document.body.appendChild(iframe);
};

// pushing the data to dataLayer if GTM id exists
export const pushDataLayer = function (newDataLayer) {
  if (getGtmId()) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(newDataLayer);
  }
};

// Initializing tagManagerUtil
export const initializeGTM = function () {
  if (getConfiguration("enableGTM") && getGtmId()) {
    loadTagManagerScript();
    insertTagManagerPreviewIframe();
  }
};

// Tracking the tite and url for pageview custom event
export const trackPageView = function () {
  if (getConfiguration("enableGTM")) {
    const { lang, marketProfileCountryCode, serverTimeStamp, trueClientIP } =
      store.getState().app;
    const userType = getCookie("userType");
    const dataLayer = {
      event: "customPageView",
      language: lang,
      source: getCookie("source"),
      url:
        window.document.location?.href.substring(
          0,
          window.document.location.href.indexOf("?"),
        ) || "",
      userType: userType || "B2C",
      userPlatform,
      browser: window.navigator.userAgent,
      countryCode: marketProfileCountryCode,
      documentTitle: window.document.title,
      domainName: window.document.domain,
      serverTimeStamp: serverTimeStamp,
      trueClientIp: trueClientIP,
    };
    pushDataLayer(dataLayer);
  }
};

// Tracking the UI custom event
export const trackUIEvent = function (dataLayer) {
  if (getConfiguration("enableGTM")) {
    const newDataLayer = {
      ...dataLayer,
      event: "UI",
    };
    pushDataLayer(newDataLayer);
  }
};

// Tracking the Filter custom event
export const trackFilterEvent = function (dataLayer) {
  if (getConfiguration("enableGTM")) {
    const newDataLayer = {
      ...dataLayer,
      event: "Filter",
    };
    pushDataLayer(newDataLayer);
  }
};

// Tracking the API custom event
export const trackAPIEvent = function (label, isSuccess) {
  if (getConfiguration("enableGTM")) {
    const dataLayer = {
      event: "API",
      category: "API",
      action: label,
      value: isSuccess ? "SUCCESS" : "FAILURE",
    };
    pushDataLayer(dataLayer);
  }
};

/**
 * Push data for trip search
 * @param  {Object} dataLayer pass all the datalayer object that is needed to track search event
 * @return {null}
 */
export const trackTripSearchResults = function (dataLayer) {
  dataLayer = {
    ...{ event: "search" },
    ...dataLayer,
  };
  pushDataLayer(dataLayer);
};

/**
 * Push data for trip search
 * @param  {Object} dataLayer pass all the datalayer object that is needed to track search event
 * @return {null}
 */
export const trackTripSearchError = (dataLayer) => {
  dataLayer = {
    ...{ event: "searchError", language: store.getState().app.lang },
    ...dataLayer,
  };
  pushDataLayer(dataLayer);
};

/**
 * Track click event of product before redirecting to detail page
 * @param  {Object} productDetail Object containing relevant details for product
 * @param  {string} productList the name of the list of product list
 * @param  {Function} callback callback function to redirect to detail view
 * @return {null}
 */
export const trackProductClick = (productDetail, productList, callback) => {
  let product = getProduct(productDetail);
  const pageType =
    typeof product.pageType !== "undefined" ? product.pageType : "undefined";
  try {
    delete product.pageType; // So as to not wrongly send it as product array
  } catch (e) {}

  let clickEventData = { products: [] };
  if (productList) clickEventData.actionField = { list: productList }; // Optional list property.
  clickEventData.products.push(product);
  var dataLayer = {
    event: "productClick",
    ecommerce: {
      click: clickEventData,
    },
    eventCallback: function () {
      callback && callback();
    },
  };
  pushDataLayer(dataLayer);
};

/**
 * Track product detail view
 * @param  {Object} productDetail Object containing relevant details for product
 * @param  {string} productList the name of the list of product list
 * @return {null}
 */
export const trackProductDetailView = (productDetail, productList) => {
  let product = getProduct(productDetail);
  const pageType =
    typeof product.pageType !== "undefined" ? product.pageType : "undefined";
  try {
    delete product.pageType; // So as to not wrongly send it as product array
  } catch (e) {}

  let detailEventData = { products: [] };
  if (productList) detailEventData.actionField = { list: productList }; // Optional list property.
  detailEventData.products.push(product);
  var dataLayer = {
    ecommerce: {
      detail: detailEventData,
    },
  };
  pushDataLayer(dataLayer);
};

/**
 * Add product to cart
 * @param  {Object} productDetail Object containing relevant details for product
 * @param  {String} currencyCode passing the currency code
 * @return {null}
 */
export const trackAddToCart = (productDetail, currencyCode) => {
  let product = getProduct(productDetail);
  let addCartData = { products: [] };
  addCartData.products.push(product);
  var dataLayer = {
    event: "addToCart",
    ecommerce: {
      currencyCode,
      add: addCartData,
    },
  };
  pushDataLayer(dataLayer);
};
