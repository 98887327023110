export const DrawerItem = class {
  constructor(
    title,
    component,
    actions = [],
    extraActions = [],
    callback,
    canClose = true,
    closeIcon = null,
  ) {
    this.title = title;
    this.component = component;
    this.actions = actions;
    this.extraActions = extraActions;
    this.callback = callback;
    this.canClose = canClose;
    this.closeIcon = closeIcon;
  }
};
