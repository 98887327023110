import { combineReducers } from "redux";

import app from "./app/appReducer";
import root from "./app/rootAuth/reducers/index";
import unAuth from "./app/rootUnAuth/reducers/index";

import appBarReducer from "./app/common/reducers/appBarReducer";
import drawerReducer from "./app/common/reducers/drawerReducer";
import literals from "./app/common/reducers/literalReducer";
import navBarReducer from "./app/common/reducers/navBarReducer";
import multiDrawerReducer from "./app/common/reducers/multiDrawerReducer";
import notification from "./app/common/reducers/notificationReducer";
import alertMessageReducer from "./app/common/reducers/alertMessageReducer";
import sort from "./app/common/reducers/SortReducer";
import twoColumnLayout from "./app/common/reducers/twoColumnReducer";

const createReducer = (asyncReducers) =>
  combineReducers({
    app,
    drawer: drawerReducer,
    multiDrawer: multiDrawerReducer,
    navBar: navBarReducer,
    appBar: appBarReducer,
    literals,
    notification,
    alertMessage: alertMessageReducer,
    root,
    sort,
    twoColumnLayout,
    unAuth,
    ...asyncReducers,
  });

export default createReducer;
