import React from "react";
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import {
  StyledEngineProvider,
  ThemeProvider,
  CssBaseline,
} from "@mui/material";
import store from "./store";
import LiteralProvider from "./app/common/components/LiteralProvider/LiteralProviderContainer";
import NotificationContainer from "./app/common/components/Notification/NotificationContainer";
import ErrorBoundary from "./app/common/components/ErrorBoundary/ErrorBoundary";
import PWA from "./app/pwa-container";
import { createRoot } from "react-dom/client";
import { createTheme } from "@mui/material/styles";

// Define the custom theme with Poppins font
const theme = createTheme({
  typography: {
    fontFamily: '"Poppins" Arial sans-serif',
  },
});

// Create a style node for JSS insertion point
const styleNode = document.createComment("jss-insertion-point");
document.head.insertBefore(styleNode, document.head.firstChild);

// Render the application
createRoot(document.getElementById("app")).render(
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ErrorBoundary level="app">
        <Provider store={store}>
          <LiteralProvider routes={["common"]}>
            <HashRouter>
              <PWA />
            </HashRouter>
            <NotificationContainer />
          </LiteralProvider>
        </Provider>
      </ErrorBoundary>
    </ThemeProvider>
  </StyledEngineProvider>,
);

// registerServiceWorker();

// let shouldShowPrompt = true;
// window.addEventListener('beforeinstallprompt', (e) => {
//     if (shouldShowPrompt) {
//         // Prevent Chrome 67 and earlier from automatically showing the prompt
//         e.preventDefault();
//         // Stash the event so it can be triggered later.
//         window.deferredPrompt = e;

//         setTimeout(() => {
//             shouldShowPrompt = false;
//             window.deferredPrompt.prompt;
//             if (window.deferredPrompt) {
//                 try {
//                     window.deferredPrompt.prompt();
//                     window.deferredPrompt = null;
//                 } catch {
//                     shouldShowPrompt = true;
//                 }
//             }
//         }, 3000);
//     }
// });
