import store from "../../store";
import {
  REGION_TYPE,
  PACKAGE_TYPE,
  TRIP_MODE,
  TRIP_PAGE_TYPE,
  HOTEL_CATEGORY,
} from "../constants/commonTripConstants";

const getTripSearchDataLayer = (searchData) => {
  let dataLayer = {
    event: "HotelPageView",
    packageType: searchData.packageType,
    hotelLocation: searchData.hotelCity || "",
    checkInOn: searchData.startDate || "",
    checkOutOn: searchData.endDate || "",
    noOfChilds: searchData.noOfChilds || 0,
    noOfAdults: searchData.noOfAdults || 0,
    totalNoOfGuests: searchData.totalNoOfGuests || 0,
    totalNoOfHotels: searchData.totalNoOfHotels || 0,
    totalNoOfRooms: searchData.totalNoOfRooms || 0,

  };
  return dataLayer;
};

const getTripRegionType = (tripCountry) => {
  const marketProfileCountryCode = store.getState().app
    .marketProfileCountryCode;
  return marketProfileCountryCode === tripCountry
    ? REGION_TYPE.DOM
    : REGION_TYPE.INT;
};

const getPageTypeFromTripMode = (productDetail) => {
  let { tripMode, isHotelDetail } = productDetail;
  let pageType = "";
  switch (tripMode) {
    case TRIP_MODE.HOTELS:
      if (!isHotelDetail) pageType = TRIP_PAGE_TYPE.HOTELS;
      else pageType = TRIP_PAGE_TYPE.HOTEL_DESCRIPTION;
      break;
    default:
      pageType = TRIP_PAGE_TYPE.TRIP;
      break;
  }
  return pageType;
};

export const noOfAdult = (rooms) => {
  return rooms?.reduce(function (total, room) {
    return total + room?.numberOfAdults;
  }, 0);
};
export const noOfchild = (rooms) => {
  return rooms?.reduce(function (total, room) {
    return total + room?.childrenAges?.length;
  }, 0);
};
export const getHotelSearchDataLayer = () => {
  let {
    searchParams: {
      hotel: { checkIn, checkOut, rooms },
    },
    locationDetails,
    hotelsIdList,
  } = store.getState().hotels;
  let hotelSearhData = {
    packageType: PACKAGE_TYPE.HOTEL,
    regionType: getTripRegionType(locationDetails.country),
    hotelCity: locationDetails.name,
    startDate: checkIn && new Date(checkIn).toDateString(),
    endDate: checkOut && new Date(checkOut).toDateString(),
    noOfChilds: noOfchild(rooms),
    noOfAdults: noOfAdult(rooms),
    totalNoOfGuests: noOfAdult(rooms) + noOfchild(rooms),
    totalNoOfHotels: hotelsIdList?.length,
    totalNoOfRooms: rooms.length,
  };
  return getTripSearchDataLayer(hotelSearhData);
};

const getProductForHotels = (productDetail) => {
  let product = {};
  product.category = productDetail.isDomestic
    ? HOTEL_CATEGORY.DOMESTIC
    : HOTEL_CATEGORY.INTERNATIONAL;
  product.name = productDetail.name;
  product.id = productDetail.id;
  if (productDetail.isHotelDetail) {
    product.brand = productDetail.providerId;
    product.price = productDetail.price;
  } else {
    product.price = productDetail.price;
    product.brand = productDetail.providerId;
  }
  return product;
};
export const getProduct = (productDetail) => {
  let product = {
    name: "",
    id: "",
    pageType: "",
    brand: "",
    price: "",
    category: "",
    variant: "",
    quantity: 1,
  };
  product.pageType = getPageTypeFromTripMode(productDetail);
  switch (productDetail.tripMode) {
    case TRIP_MODE.HOTELS:
      product = { ...product, ...getProductForHotels(productDetail) };
      break;
    default:
      break;
  }
  return product;
};

export const getHotelProductDetails = (price) => {
  const {
    app: { marketProfileCountryCode },
    hotels: {
      locationDetails: { name, country, id },
    },
  } = store.getState();
  const productDetail = {
    isDomestic: marketProfileCountryCode === country,
    name: name,
    id: id,
    price: price,
    tripMode: TRIP_MODE.HOTELS,
  };
  return productDetail;
};
