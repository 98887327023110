import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";

import createReducer from "./reducers";
import { PRODOCTION_MODE } from "./app/constants/Text";

// loading middleware conditionally depending on env.
const middleWare = [
  thunk,
  process.env.NODE_ENV !== PRODOCTION_MODE && logger,
].filter(Boolean);

const initializeStore = () => {
  const store = createStore(
    createReducer(),
    {}, // NOTE: Don't put this in a prod build, just doing this for the demo.
    applyMiddleware(...middleWare),
  );

  store.asyncReducers = {};
  store.injectReducer = (key, reducer) => {
    store.asyncReducers[key] = reducer;
    store.replaceReducer(createReducer(store.asyncReducers));
    return store;
  };

  return store;
};

const store = initializeStore();

export default store;
